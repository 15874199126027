//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Message } from 'element-ui';
import encrypt from '@/common/rsa';
import { validPassword } from '@/common/validate';
export default {
  components: {},
  name: 'login',
  data: function data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (!regexps.username.test(value)) {
    //     callback(new Error('请输入正确的用户名'));
    //   } else {
    //     callback();
    //   }
    // };
    var passwordRule = function passwordRule(rule, value, callback) {
      if (!validPassword(value)) {
        return callback(new Error('密码限制为8-10位，至少包含字母、数字、符号中的两种'));
      }

      callback();
    };

    return {
      loginForm: {
        //  username: 'enacs',
        // password: 'enacs',
        username: '',
        password: '',
        time: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '请输入用户名'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: passwordRule
        }]
      },
      // RSA加密后密文变量
      loginParams: {},
      passwordType: 'password',
      loading: false,
      showDialog: false
    };
  },
  methods: {
    showPwd: function showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    handleLogin: function handleLogin() {
      var _this = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;

          _this.encryption();

          _this.$store.dispatch('LoginByUsername', _this.loginParams).then(function () {
            _this.loading = false;

            _this.$router.push({
              path: '/'
            });
          }).catch(function () {
            // Message.error(msg);
            _this.loading = false;
          });
        } else {
          Message.error('用户名密码格式不正确');
          return false;
        }
      });
    },
    // RSA加密
    encryption: function encryption() {
      this.loginForm.time = Date.now(); // 使用'<_!_>' 作为分隔符拼接字符串

      var data = "".concat(this.loginForm.username, "<_!_>").concat(this.loginForm.password, "<_!_>").concat(this.loginForm.time); // const data = 'userName password timestamp'

      this.loginParams = {
        encrypted_data: encrypt.setRSA(data)
      };
    }
  }
};